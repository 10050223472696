import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import axios from 'axios';
import InputMask from 'react-input-mask';

const LeadForm: React.FC = () => {

  const { partner } = useParams<{ partner?: string }>();

  const [formData, setFormData] = useState({
    name: '',
    cpf: '',
    birthdate: '',
    product: 'Medik Diamante',
    total: 0,
    phone: '',
    email: '',
    companyName: '',
    quantityBeneficiaries: 1,
  });

  const [errors, setErrors] = useState<any>({
    phone: null,
    email: null,
  });

  const [loading, setLoading] = useState(false)

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  function formatPhoneNumber(input:any) {
    const cleaned = input.replace(/\D/g, '');

    if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    } else if (cleaned.length > 2) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;    
    } else {
      return cleaned;
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.target;

    let newValue = value
    if (name === 'phone') {
      const cleaned = value.replace(/\D/g, '');
      if (name === 'phone' && cleaned.length > 11) {
        return; // Não atualize o estado se exceder o limite
      }
      newValue = formatPhoneNumber(value);
    }

    // if (name === 'phone' && !/^[0-9]*$/.test(value)) {
    //   return; // Não atualize o estado se houver caracteres não numéricos
    // }
    setFormData({ ...formData, [name]: newValue.trimStart() });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value as string });
  };

  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    setLoading(true);


    // Validação
    const { name, phone, email, companyName, quantityBeneficiaries, birthdate, total, cpf, product } = formData;
    if (!name || !phone || !email || !companyName || !quantityBeneficiaries) {
      setLoading(false)
      alert('Todos os campos são obrigatórios.');
      return;
    }


    // Validação de e-mail
    let errorEmail = null;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      errorEmail = 'E-mail inválido'
    }


    // Validação de phone
    let errorPhone = null;
    const phonePattern = /^\(\d{2}\) \d{5}-\d{4}$/
    if (!phonePattern.test(phone)) {
      errorPhone = 'Telefone inválido';
    }

    setErrors({
      email: errorEmail,
      phone: errorPhone,
    })
    if (errorEmail || errorPhone) {
      setLoading(false)
      return;
    }

    try {
      const options = {
        method: 'POST',
        url: 'https://us-central1-beneflex.cloudfunctions.net/createAccession',
        headers: { 'Content-Type': 'application/json' },
        data: {
          email,
          name,
          phone,
          companyName,
          quantityBeneficiaries,
          birthdate,
          partner,
          cpf,
          product,
          total
        }
      };


      await axios.request(options);
      alert('Enviado com sucesso');
    } catch (error) {
      console.log(error);
      alert('Falhar ao enviar');
    }
    setLoading(false)
  };

  useEffect(()=> {
    const {quantityBeneficiaries, product} = formData
    if (quantityBeneficiaries && product) {
      switch (product) {
        case "Medik Diamante":
          setFormData({ ...formData, total: quantityBeneficiaries * 59.9 });
          break;
        
        case "Medik Ouro":
        setFormData({ ...formData, total: quantityBeneficiaries * 49.9 });
        break;

        case "Medik 100":
          setFormData({ ...formData, total: quantityBeneficiaries * 39.9 });
          break;
      
        default:
          break;
      }
    }
  }, [formData])


  return (
    <Container>
      <Typography variant="h3" align="center">
        Formulário de Adesão
        {/* {partner} */}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" align="center" margin={2}>
          Empresa
        </Typography>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome da Empresa"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
            disabled={loading}
          />
        </Grid>
        <Typography variant="h6" align="center" margin={2}>
          Dados do titular do plano
        </Typography>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome Completo"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="CPF"
              name="cpf"
              value={formData.cpf}
              onChange={handleChange}
              required
              disabled={loading}
              error={errors.cpf}
              helperText={errors.cpf}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data de nascimento"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleChange}
              required
              disabled={loading}
              error={errors.birthdate}
              helperText={errors.birthdate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading}
              error={errors.email}
              helperText={errors.email}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefone Celular"
              placeholder='(XX) XXXXX-XXXX'
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              disabled={loading}
              error={errors.phone}
              helperText={errors.phone}
            />
          </Grid>

        </Grid>
        <Typography variant="h6" align="center" margin={2}>
          Escolha o Produto
        </Typography>
        <Grid container spacing={2} marginTop={2}>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Produto</InputLabel>
              <Select
                name="product"
                value={formData.product}
                onChange={handleSelectChange}
                required
                disabled={loading}
              >
                <MenuItem value="Medik Diamante">Medik Diamante - 59,90 por mês para cada beneficiário</MenuItem>
                <MenuItem value="Medik Ouro">Medik Ouro - 49,90 por mês para cada beneficiário</MenuItem>
                <MenuItem value="Medik 100">Medik 100 - 39,90 por mês para cada beneficiário</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Quantidade de Beneficiários</InputLabel>
              <Select
                name="quantityBeneficiaries"
                value={String(formData.quantityBeneficiaries)}
                onChange={handleSelectChange}
                required
                disabled={loading}
              >
                <MenuItem value={1}>1 - Apenas o titular (EU)</MenuItem>
                <MenuItem value={2}>2 - Titular (Eu) + 1 Dependente</MenuItem>
                <MenuItem value={3}>3 - Titular (Eu) + 2 Dependente</MenuItem>
                <MenuItem value={4}>4 - Titular (Eu) + 3 Dependente</MenuItem>
                <MenuItem value={5}>5 - Titular (Eu) + 4 Dependente</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Total "
              placeholder='R$ 00,00'
              name="total"
              value={formData.total.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })}
              disabled
            />
          </Grid>

        </Grid>
        <Grid container spacing={2} margin={2}>
        <div>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <a href="" target='_blank'>Li o termo de desconto em folha de pagamento</a>, e AUTORIZO o débito mensal dos valores referente ao pacote MEDIK escolhido.
          </label>
        </div>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || !isChecked}
          style={{ margin: '20px' }}
        >
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default LeadForm;
